<script>
import About from '@/components/About/About.vue';
export default {
  components:{
      About,
  },
  setup(){
    return{}
  }
}
</script>

<template>
<About />
</template>

<style lang="scss" scoped>
a{
  color: #fff;
  position: relative;
  z-index: 5;
}
</style>