export default{
    handIsMenu(state,bool){
        state.isMobileMenu = bool;
    },
    handIsAboutFalse(state,bool){
        state.isAbout = bool
    },
    handIsAboutTrue(state,bool){
        state.isAbout = bool
    },
}